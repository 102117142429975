import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import SEO from '../../components/Seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO description="Instructions to install TypeScript with Create React App." title="Create React App" keywords={['create react app, cra']} mdxType="SEO" />
    <h1 {...{
      "id": "create-react-app"
    }}>{`Create React App`}</h1>
    <p>{`For additional details, please refer to the `}<a parentName="p" {...{
        "href": "https://facebook.github.io/create-react-app/docs/adding-typescript"
      }}>{`Create React App documentation`}</a>{`.`}</p>
    <h2 {...{
      "id": "create-a-new-app-with-typescript"
    }}>{`Create a New App with TypeScript`}</h2>
    <h3 {...{
      "id": "using-npm"
    }}>{`Using NPM`}</h3>
    <pre><code parentName="pre" {...{}}>{`npx create-react-app your-app-name --typescript
`}</code></pre>
    <p>{`or`}</p>
    <h3 {...{
      "id": "using-yarn"
    }}>{`Using Yarn`}</h3>
    <pre><code parentName="pre" {...{}}>{`yarn create react-app your-app-name --typescript
`}</code></pre>
    <h2 {...{
      "id": "add-typescript-to-an-existing-create-react-app-application"
    }}>{`Add TypeScript to an Existing Create React App Application`}</h2>
    <h3 {...{
      "id": "using-npm-1"
    }}>{`Using NPM`}</h3>
    <pre><code parentName="pre" {...{}}>{`npm install --save typescript @types/node @types/react @types/react-dom @types/jest
`}</code></pre>
    <p>{`or`}</p>
    <h3 {...{
      "id": "using-yarn-1"
    }}>{`Using Yarn`}</h3>
    <pre><code parentName="pre" {...{}}>{`yarn add typescript @types/node @types/react @types/react-dom @types/jest
`}</code></pre>
    <p>{`After installing these (and any other `}<a parentName="p" {...{
        "href": "http://definitelytyped.org/"
      }}>{`types`}</a>{` you may need), rename your `}<inlineCode parentName="p">{`.js`}</inlineCode>{` files to `}<inlineCode parentName="p">{`.tsx`}</inlineCode>{` and restart your development server.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=wvvNUr4lgaM"
      }}>{`This video`}</a>{` runs through the instructions on this page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      